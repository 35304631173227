<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <PageHeader title="Offers Overview">
      <template v-slot:actions>
        <q-btn
          flat
          label="Edit Tags"
          @click="promptOfferTagsLibraryEditor"
          color="subtle"
        />
        <q-btn label="New Offer" to="/manage/offers/create" color="primary" />
      </template>
    </PageHeader>

    <DataTable
      table-key="offersOverview"
      row-key="id"
      title="Offers"
      :columns="columns"
      :rows="offers"
      :loading="loading"
      :pagination="pagination"
      :downloadable="true"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <template v-slot:name-append="row">
        <CopyToClipboardButton :copy-value="row.name" />
      </template>
      <template v-slot:actions="row">
        <ActionsDropdownButton
          :actions="[
            {
              action: () =>
                $router.push({
                  path: '/manage/offers/edit/' + row.id
                }),
              label: 'Edit'
            },
            {
              action: () =>
                $router.push({
                  path: '/manage/offers/create/' + row.id
                }),
              label: 'Duplicate'
            },
            {
              action: () =>
                $router.push({
                  path: '/manage/offers/split-tests/edit/' + row.id
                }),
              label: 'Edit Split Tests'
            },
            {
              action: () => testOfferLink(row),
              label: 'Test Offer Link'
            },
            {
              action: () =>
                copyToClipboard(
                  'utm_source=' + row.source_id + '&utm_campaign=' + row.id
                ),
              label: 'Copy URL Params'
            },
            {
              action: () => promptDeleteOffer(row),
              label: 'Delete'
            }
          ]"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete";
import { Dialog } from "quasar";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import OfferTagsLibraryEditorModal from "@/components/OfferTagsLibraryEditorModal";
import DataTable from "@/components/DataTable";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton";
import CopyToClipboardButton from "@/components/UI/CopyToClipboardButton";

export default {
  name: "OffersOverview",
  components: {
    CopyToClipboardButton,
    ActionsDropdownButton,
    DataTable,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      loading: true,
      filter: "",
      pagination: {
        sortBy: "id",
        descending: true,
        page: 1,
        rowsPerPage: 25
      },
      columns: [
        {
          name: "id",
          label: "Offer ID",
          field: "id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "name",
          label: "Offer Name",
          field: "name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "source_id",
          label: "Source ID",
          field: "source_id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "brand_name",
          label: "Brand",
          field: "brand_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "channel_name",
          label: "Channel",
          field: "channel_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "campaign_type_name",
          label: "Campaign Type",
          field: "campaign_type_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "vertical_name",
          label: "Vertical",
          field: "vertical_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "country_code",
          label: "Country",
          field: "country_code",
          sortable: true,
          type: "dimension"
        },
        {
          name: "tags",
          label: "Tags",
          field: row =>
            typeof this.offerTagsListByOfferId[row.id] !== "undefined"
              ? this.offerTagsListByOfferId[row.id]
              : "-",
          sortable: false,
          filterable: false
        },
        {
          name: "actions",
          label: "",
          field: "",
          filterable: false
        }
      ]
    };
  },
  computed: {
    offers() {
      return this.loading ? [] : this.$store.state.offers.list;
    },
    offerTagsListByOfferId() {
      let offerTagsListByOfferId = {};
      let offerTagsByOfferId = this.$store.getters["offers/tags/byOfferId"];
      Object.keys(offerTagsByOfferId).forEach(offerId => {
        offerTagsByOfferId[offerId].sort((a, b) => (a.tag > b.tag ? 1 : -1));
        if (typeof offerTagsListByOfferId[offerId] === "undefined") {
          offerTagsListByOfferId[offerId] = "";
        }
        offerTagsByOfferId[offerId].forEach(offerTagData => {
          offerTagsListByOfferId[offerId] += ", " + offerTagData.tag;
          if (
            offerTagsListByOfferId[offerId].charAt(0) === "," &&
            offerTagsListByOfferId[offerId].charAt(1) === " "
          ) {
            offerTagsListByOfferId[offerId] = offerTagsListByOfferId[
              offerId
            ].substring(2);
          }
        });
      });
      return offerTagsListByOfferId;
    }
  },
  mounted() {
    this.$store.dispatch("offers/tags/POPULATE_LIST").finally(() => {
      this.$store.dispatch("offers/POPULATE_LIST").finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 100);
      });
    });
  },
  methods: {
    testOfferLink(offer) {
      if (!this.$store.getters["brands/isSet"]) {
        this.$store.dispatch("brands/POPULATE_LIST").finally(() => {
          window.open(this.generateOfferTestLink(offer), "_blank");
        });
      } else {
        window.open(this.generateOfferTestLink(offer), "_blank");
      }
    },
    generateOfferTestLink(offer) {
      return (
        "https://" +
        this.$store.getters["brands/byId"][offer.brand_id]["subdomain"] +
        "/in.php?" +
        "&utm_source=" +
        offer.source_id +
        "&utm_campaign=" +
        offer.id
      );
    },
    promptDeleteOffer(offer) {
      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor: offer.name
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        this.$store
          .dispatch("offers/POST_REQUEST", {
            endpoint: "delete",
            params: { id: offer.id }
          })
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            dialog.hide();
          })
          .catch(() => {
            this.triggerActionOutcomeAlert("delete", false);
          })
          .finally(() => {
            dialog.submitting = false;
          });
      });
    },
    promptOfferTagsLibraryEditor() {
      Dialog.create({
        component: OfferTagsLibraryEditorModal
      }).onOk(dialog => {
        dialog.submitting = false;
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
